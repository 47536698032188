.Toastify {
  .Toastify__toast-container--top-right {
    top: 6em;
    right: 2em;
  }
  .Toastify__toast-container {
    width: 400px;
    .Toastify__toast {
      border-radius: 10px;
      .Toastify__toast-body {
        .custom-toast {
          padding: 10px;
          min-height: 6rem;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 50px;
              max-width: inherit;
            }
          }
          .text {
            font-size: 18px;
            margin-left: 15px;
            font-family: $font-regular;
          }
        }
      }
    }
    .Toastify__toast--success {
      background-color: $sludge;
    }
    .Toastify__toast--warning {
      background-color: $orange;
    }
  }
}

@media #{$max-media-mobile} {
  .Toastify {
      .Toastify__toast-container--top-right {
        top: 2em;
        right: 2em;
      }
      .Toastify__toast-container {
        right: 20px;
        left: auto;
        width: 250px;
        .Toastify__toast {
          border-radius: 10px;
          .Toastify__toast-body {
            .custom-toast {
              padding: 0px;
              min-height: 5rem;
              .icon {
                img {
                  width: 40px;
                }
              }
              .text {
                font-size: 14px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
}
