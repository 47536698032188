@import './variables.scss';

@import './fonts.scss';

@import './toastify.scss';

* {
  outline: 0;
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

.disable-scroll {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
}

html,
body {
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: $font-regular, sans-serif;
}

.nav {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}

.sidebar .nav {
  margin-top: 20px;
}

.link {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center-y {
  align-items: center;
}

.center-x {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.ui {
  &.page {
    &.dimmer {
      padding: 5%;
      align-items: center;
      justify-items: center;
      .ui {
        &.scrolling {
          &.modal {
            margin: auto !important;
            position: relative !important;
          }
        }
      }
    }
  }
  .modal {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    position: relative;
    margin: auto !important;
  }
}

@mixin disabled {
  opacity: 0.8;
  cursor: not-allowed;
  background-image: none;
  box-shadow: none;
  pointer-events: none;
}

.ui {
  &.button:disabled {
    @include disabled;
  }
  &.buttons .disabled.button {
    @include disabled;
  }
  &.disabled {
    &.active.button {
      @include disabled;
    }
    &.button {
      @include disabled;
      &:hover {
        @include disabled;
      }
    }
  }
}

.custom-toast {
  display: flex;
  span {
    i {
      font-size: 35px;
      width: 40px;
      margin-left: 10px;
    }
  }
}

.main-panel > .content {
  min-height: calc(100vh - 140px);
}

.Toastify__close-button {
  color: #fff;
  font-weight: normal;
  font-size: 30px;
  right: 5px;
  top: 4px;
  position: relative;
}

.notifications-tr {
  max-width: 350px;
}

.notification-visible {
  display: flex;
  min-height: 60px;
  justify-content: center;
  align-items: center;
}

.pd-0 {
  padding: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pd-10 {
  padding: 10px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-20 {
  padding-bottom: 20px;
}

.mg-10 {
  margin: 10px;
}

.mg-auto {
  margin: auto;
}

.mg-0 {
  margin: 0px;
}

.row {
  margin: 0px;
}

.hide {
  display: none;
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-itens {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

strong {
  font-family: DMSans Bold;
}

.form-footer {
  display: flex;
  text-align: right;
  flex-direction: row;
  justify-content: flex-end;
  &.modal-footer {
    border: none;
    padding: 0px;
    justify-content: space-between;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }
}

.none {
  display: none !important;
}

.block {
  display: block !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.form-footer,
.actions {
  .ui {
    &.button {
      height: 40px;
      color: $white;
      outline: none;
      font-weight: 500;
      font-size: 14px;
      min-width: 150px;
      box-shadow: none;
      background-color: $primary;
      text-transform: uppercase;
      border: solid 1px $primary-black;
    }
    &.button:hover,
    &.button:focus {
      box-shadow: none;
      background-image: none;
      background-color: $primary-black;
    }
  }
}

.text-center {
  text-align: center;
}

.InputMaterial {
  button {
    outline: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  input {
    width: 100%;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
}

.ui.grid .row {
  flex-grow: initial;
  padding: 0px;
}

@media only screen and (min-width: 2200px) {
  .ui.column.grid > [class*='sixteen wide'].column,
  .ui.grid > .column.row > [class*='sixteen wide'].column,
  .ui.grid > .row > [class*='sixteen wide'].column,
  .ui.grid > [class*='sixteen wide'].column {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    width: 100%;
    margin: 0 0;
    box-shadow: none;
    padding: 1rem 1rem;
  }
  .ui.grid.grid.grid > .row > [class*='computer only'].column:not(.tablet),
  .ui.grid.grid.grid > [class*='computer only'].column:not(.tablet),
  .ui.grid.grid.grid > [class*='computer only'].row:not(.tablet),
  .ui[class*='computer only'].grid.grid.grid:not(.tablet) {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  .ui.container {
    width: 1450px;
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 767px) {
  .ui.container {
    width: 1199px;
    padding: 0 30px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .ui.stackable.grid {
    width: auto;
    margin: 0 !important;
  }
  .ui.modal > .actions {
    padding: 1rem 1rem 1em !important;
  }

  .ui.attached.tabular.menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    .item {
      text-align: center;
      margin-bottom: 10px;
      border: solid 1px $grey;
    }
  }
}
