$black: #000;
$white: #fff;
$green: #8CD666;
$grey: #ababab;
$grey-back: #646464;
$error: red;
$orange: #ff7126;
$blue: #5FBBD6;
$echo: #7AD4E0;

$text: #707070;
$title: #0B3440;
$subtitle: #5F5F5F;
$alpha: #79959c;
$delta: #F2F9FC;
$sludge: #0b3440;
$ice: #DCDBD9;

$darkblue: #08405d;
$testimony-background: #0b3440;
$testimony-card-background: #276172;

$foxtrot: #ffac26;
$label: #b1b1b1;
$boxShadow: #cfcfcf;
$bravo_black: #5f5f5f;
$sell-miles-fixed: #4dc4ed;
$ournumber-background: #41a2c4;
$payment-background: #3788a5;
$miles-airlines-background: #4da6c4;

$light-gray: #646464;
$primary: #2e87a5;
$primary-black: #26677d;
$border-input: #e3e3e3;

$max-media-mobile-small: "(max-width : 360px)";
$max-media-mobile: "(max-width : 500px)";
$max-media-tablet: "(max-width : 840px)";
$max-media-lying: "(max-width : 1000px)";
$max-media-desktop-small: "(max-width : 1200px)";
$max-media-desktop-large: "(max-width : 1600px)";
$min-media-desktop-large: "(min-width : 1800px)";
$min-media-desktop-small: "(min-width : 1200px)";

$font-bold: "DMSans Bold";
$font-regular: "DMSans Regular";
$font-semibold: "DMSans Semibold";
$font-extrabold: "OpenSans ExtraBold";

$larger-shadow: 0 0.8rem 2.8rem rgba(0, 0, 0, 0.175);
$regular-shadow: 0 0.5rem 1.6rem rgba(0, 0, 0, 0.15);
$small-shadow: 0 0.12rem 0.2rem rgba(0, 0, 0, 0.075);
