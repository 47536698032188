/* FONTS ------------------------------------------------------------ * /


/* DMSans Regular */

@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: "DMSans Regular";
  src: url("../fonts/dm_sans/DMSans-Regular.ttf"),
  url("../fonts/dm_sans/DMSans-Italic.ttf");
}

/* DMSans Bold */

@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: "DMSans Bold"; 
  src: url("../fonts/dm_sans/DMSans-Bold.ttf"),
  url("../fonts/dm_sans/DMSans-BoldItalic.ttf");
}

/* DMSans Semibold */

@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: "DMSans Semibold";
  src: url("../fonts/dm_sans/DMSans-Medium.ttf"),
  url("../fonts/dm_sans/DMSans-MediumItalic.ttf");
}

/* FONTS ------------------------------------------------------------ * /

/* OpenSans ExtraBold */

@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: "OpenSans ExtraBold";
  src: url("../fonts/open-sans/OpenSans-ExtraBold.ttf");
}

/* OpenSans Light */

@font-face {
  font-display: swap;
  unicode-range: U+000-5FF;
  font-family: "OpenSans Light";
  src: url("../fonts/open-sans/OpenSans-Light.ttf"),
    url("../fonts/open-sans/OpenSans-LightItalic.ttf");
}