@import '../variables.scss';

.btn {
  border-width: $border-thick;
  background-color: $transparent-bg;
  font-weight: $font-weight-normal;

  @include opacity(0.8);
  padding: $padding-base-vertical $padding-base-horizontal;

  @include btn-styles($default-color, $default-states-color);

  &:hover,
  &:focus {
    @include opacity(1);
    outline: 0 !important;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    @include box-shadow(none);
    outline: 0 !important;
  }

  &.btn-icon {
    padding: $padding-base-vertical;
  }
}

// Apply the mixin to the buttons
//.btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary {
  @include btn-styles($primary-color, $primary-states-color);
}
.btn-success {
  @include btn-styles($success-color, $success-states-color);
}
.btn-info {
  @include btn-styles($info-color, $info-states-color);
}
.btn-warning {
  @include btn-styles($warning-color, $warning-states-color);
}
.btn-danger {
  @include btn-styles($danger-color, $danger-states-color);
}
.btn-neutral {
  @include btn-styles($white-color, $white-color);

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $white-color;
    color: $default-color;
  }

  &.btn-fill,
  &.btn-fill:hover,
  &.btn-fill:focus {
    color: $default-color;
  }

  &.btn-simple:active,
  &.btn-simple.active {
    background-color: transparent;
  }
}

.btn {
  &:disabled,
  &[disabled],
  &.disabled {
    @include opacity(0.5);
  }
}
.btn-round {
  border-width: $border-thin;
  border-radius: $btn-round-radius !important;
  padding: $padding-round-vertical $padding-round-horizontal;

  &.btn-icon {
    padding: $padding-round-vertical;
  }
}
.btn-simple {
  border: $none;
  font-size: $font-size-medium;
  padding: $padding-base-vertical $padding-base-horizontal;

  &.btn-icon {
    padding: $padding-base-vertical;
  }
}
.btn-lg {
  @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
  font-weight: $font-weight-normal;
}
.btn-sm {
  @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
.btn-xs {
  @include btn-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $border-radius-small);
}
.btn-wd {
  min-width: 140px;
}

.btn-group.select {
  width: 100%;
}
.btn-group.select .btn {
  text-align: left;
}
.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

button {
  &.ui {
    &.custom {
      color: #fff;
      border: 0px;
      font-size: 14px;
      font-weight: bold;
      padding: 10px 25px;
      transition: all -0.8s ease-out;
      background: #2e87a5;
      width: 150px;
      font-family: $font-regular;
      min-height: 40px;
      &:hover {
        color: #fff;
        background: #2e87a5;
      }
    }
    &.basic {
      &.custom {
        border-radius: 25px;
        color: #26677d;
        font-size: 16px;
        font-weight: normal;
        padding: 15px 30px;
        border: solid 1px #26677d;
        font-family: $font-regular;
        min-height: 40px;
      }
    }
  }
}

/* Buttons */
button.button-edit {
  margin: 0px 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: transparent;
  border: solid 1px #8a8a8a;
  filter: invert(50%);
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background: url('../../icons/edit.png');
    position: relative;
    background-size: 18px;
    background-repeat: no-repeat;
  }
}

button.button-delete {
  margin: 0px 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: transparent;
  border: solid 1px #8a8a8a;
  filter: invert(50%);
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background: url('../../icons/delete.png');
    position: relative;
    background-size: 18px;
    background-repeat: no-repeat;
  }
}

button.button-check {
  margin: 0px 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: transparent;
  border: solid 1px #8a8a8a;
  filter: invert(50%);
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background: url('../../icons/tick.png');
    position: relative;
    background-size: 18px;
    background-repeat: no-repeat;
  }
}

button.button-config {
  margin: 0px 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: transparent;
  border: solid 1px #8a8a8a;
  filter: invert(50%);
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background: url('../../icons/settings.png');
    position: relative;
    background-size: 18px;
    background-repeat: no-repeat;
  }
}

button.button-news {
  margin: 0px 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: transparent;
  border: solid 1px #8a8a8a;
  filter: invert(50%);
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background: url('../../icons/post.png');
    position: relative;
    background-size: 18px;
    background-repeat: no-repeat;
  }
}

button.button-search {
  margin: 0px 5px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: transparent;
  border: solid 1px #8a8a8a;
  filter: invert(50%);
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background: url('../../icons/loupe.png');
    position: relative;
    background-size: 18px;
    background-repeat: no-repeat;
  }
}

button.button-delete:hover,
button.button-edit:hover {
  filter: invert(20%);
  border: solid 1px #6b6b6b;
}

.add-element {
  height: 40px;
  width: 40px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 22px;
  font-weight: 500;
  right: 20px;
  outline: 0;
  border: none;
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
}
